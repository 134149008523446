import React, { useState, useEffect } from 'react';
import Editor from '@draft-js-plugins/editor';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import createToolbarPlugin from '@draft-js-plugins/static-toolbar';
import { BoldButton, ItalicButton, HeadlineOneButton, HeadlineTwoButton } from '@draft-js-plugins/buttons';
import '@draft-js-plugins/static-toolbar/lib/plugin.css';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    editorContainer: {
        border: '1px solid #ddd',
        minHeight: 200,
        padding: 10,
    },
}));

// Initialize the toolbar plugin
const toolbarPlugin = createToolbarPlugin();
const { Toolbar } = toolbarPlugin;

export default function RichTextEditor(props) {
    const classes = useStyles();
    const dataDecoded = Buffer.from(props.initialData, 'base64').toString('utf-8');
    const blocksFromHTML = convertFromHTML(dataDecoded);
    const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);

    // Set up the editor state
    const [editorState, setEditorState] = useState(() => EditorState.createWithContent(contentState));

    useEffect(() => {
        if (typeof props.getEditorContentHtml === 'function') {
            props.getEditorContentHtml(() => {
                const currentContent = editorState.getCurrentContent();
                const htmlContent = stateToHTML(currentContent);
                return Buffer.from(htmlContent, 'utf-8').toString('base64');
            });
        }
    }, [editorState, props]);

    return (
        <div className={classes.editorContainer}>
            <Toolbar>
                {(externalProps) => (
                    <>
                        <BoldButton {...externalProps} />
                        <ItalicButton {...externalProps} />
                        <HeadlineOneButton {...externalProps} />
                        <HeadlineTwoButton {...externalProps} />
                    </>
                )}
            </Toolbar>
            <Editor editorState={editorState} onChange={setEditorState} plugins={[toolbarPlugin]} />
        </div>
    );
}

RichTextEditor.propTypes = {
    htmlContent: PropTypes.string,
    getEditorContentHtml: PropTypes.func.isRequired,
};
